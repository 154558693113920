
import Vue from "vue";

export default Vue.extend({
  name: "MentorcastAchievements",
  props: {
    isSession: Boolean,
    user: Object
  },
  data() {
    return {
      isConfirmed: false
    };
  },
  methods: {
    confirmed() {
      this.isConfirmed = true;
      console.log("Confirmed was clicked!");
    }
  }
});
